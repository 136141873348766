import React from "react";
import "../styles.css";
import {
  Formn,
  Label,
  Input,
  ContainerTerms,
  ContainerButtonCA,
  Buttonn,
  MsjFail,
  SelectPM,
  SelectT,
  InputState,
  SelectService,
  SelectPhysiTer,
  Selectll,
  SelectSW,

  Titulo,
  InputZip
} from "../elements/Forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faShoppingBasket,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons";
import Navbar from "../componentes/Navbar";
import "../styles.css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "react-router-dom";
import '../componentes/GlobalVariables';
import axios from "axios";
import InputMask from 'react-input-mask';
import QR from "../img/athm_qrcode_sticker.png"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { withRouter } from 'react-router-dom';
const Swal = require('sweetalert2')

/**
 * Componente encargado de registrar un nuevo negocio de un cliente
 */
class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        bussinessname: "",
        merchantnumber: "",
        mobilephone: "",
        phone: "",
        address: "",
        address2: "",
        state: "PR",
        zipcode: "",
        nameoncard: "",
        securitycode: "",
        creditcarnumber: "",
        terms: null,
        town: 1,
        service: "",
        software: "",
        fiscalterminals: 1,
        paymethod: "CREDIT-CARD",
        cardtype: "Visa",
        expDateM: "",
        expDateY: "",
        routeNumber: "",
        accountNumber: "",
        accountNumber2: "",
        accountNameBank: "",
        accountNumberBank: "",
        routeNumberBank: "",
        recurringPayments: false,
        id_cv: null,
        amount: 0,
        mask: "9999-9999-9999-9999",
        maskCvv2: "999"
      },
      isSaveToken: false,
      sc: '',
      mc: '',
      void: '',
      res: {
        typeCard: [
          {
            "id": 1,
            "value": "Visa"
          }
        ],
        service: [
          {
            "id": 1,
            "value": "Ivu Basic[15.00/mount]"
          }
        ],
        towns: [
          {
            "id": 1,
            "value": "Adjuntas"
          }],
        software: [
          {
            id: 1,
            value: "Retail Manager",
          },
        ],
        year: "",
        month: "",
        numTerm: [
          {
            "id": 1,
            "value": "20"
          }
        ]
      },
      valid: null,
      validForm: null,
      loading: null,
      error: "",

      expressiones: {
        name: /^[a-zA-ZÀ-ÿ\s]{3,40}$/, // Letras y espacios, pueden llevar acentos.
        email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        password: /^.{4,12}$/, // 4 a 12 digitos.
        user: /^[a-zA-Z0-9_-]+[a-zA-ZÀ-ÿ\s]{4,16}$/, // Letras, numeros, guion y guion_bajo

        merchantNumber: /^\d{11}$/,

        nameCard: /^[a-zA-ZÀ-ÿ\s]{10,40}$/,
        nameCount: /^.+/,
        securityCode: /^\d{3}$/,
        securityCode2: /^\d{4}$/,
        zipCode: /^\d{5}$/,

        state: /^[a-zA-ZÀ-ÿ\s]{3,10}$/,
        routeNumber: /^\d{4,9}$/,
        accountNumber: /^\d{4,16}$/,
      },
      automaticPay: false,
      onePay: true,
      serviceValue: 99,
      additionalTerminal: 99,
      totalAmount: 99,
      paymentMethods: [],
      valuesAdditionalTerminal: {},
      token: ''
    };
  }

  /**
   * Se obtiene la información de los servicios, software y valor de la terminal adicional
   */
  componentDidMount() {
    const token = localStorage.getItem('loggedIvuUser')
    this.setState({
        token: token
    })
    // Acceder a los parámetros de consulta en la URL
    // Accede al valor del estado pasado en la propiedad `location`
    /* const additionalTer = this.props.location.state.additional;
    this.setState({
      additionalTerminal: additionalTer
    }) */
    /*  const config = {
       method: 'get',
       url: global.URLBack + `/query/register/loadData/3`,
     }
 
     axios(config)
       .then(res => {
         this.setState({
           res: res.data
         })
         this.setState({
           data: {
             ...this.state.data,
             service: res.data.service[0].value,
             software: res.data.software[0].value,
           }
         })
 
       }).catch(err => {
 
       }) */

    const aditionalTerminalsConfig = {
      method: "get",
      url: global.URLBack + `/query/register/aditionalTerminals`,
    };

    axios(aditionalTerminalsConfig).then(response => {
      if (Number(response.status) === 200) {
        this.setState({ valuesAdditionalTerminal: response.data })
      }
    }).catch(err => {

    })

    const config = {
      method: "get",
      url: global.URLBack + `/query/register/loadData/3`,
    };

    axios(config)
      .then((res) => {
        this.setState({
          res: res.data,
        }, () => {
          /**
           * Se realizó está modificación con respecto a la primer versión debido a que de esta manera
           * listan los servicios activos 
           */
          const config2 = {
            method: 'get',
            url: global.URLBack + `/dashBoard/service`
          }

          axios(config2)
            .then((response) => {
              this.setState((prevState) => ({
                res: { ...prevState.res, service: response.data },
                data: { ...prevState.data, service: response.data[0].serviceName, software: res.data.software[0].value }
              }));

              const config3 = {
                method: 'get',
                url: global.URLBack + `/dashBoard/superUser/paymentMethods`,
              }

              axios(config3).then(response2 => {
                this.setState({ paymentMethods: response2.data })
              }).catch(error => {
              })

            }).catch(err => {
            })
        });

      })
      .catch((err) => { });

  }

  /**
   * Metodo utilizado para validar los campos del formulario de registro
   * @returns True si todos los campos se encuentran correctos, False en caso de que algun campo sea invalido.
   */
  validation = () => {
    if (
      this.state.data.bussinessname === ""
    ) {
      this.setState({
        valid: false,
        error:
          "El nombre del negocio debe ser de mínimo 4 caracteres.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.merchantnumber === "" ||
      !this.state.expressiones.merchantNumber.test(this.state.data.merchantnumber)
    ) {
      this.setState({
        valid: false,
        error:
          "El número de registro de comerciante debe ser máximo de 11 dígitos.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.data.paymethod === "BANK-ACCOUNT") {
      if (this.state.automaticPay) {
        if (this.state.void === "") {
          this.setState({
            valid: false,
            error: "Debe ingresar un cheque void",
          });
          return false;
        } else {
          this.setState({
            valid: true,
            error: "",
          });
        }
      }
    }
    if (this.state.data.mobilephone === "" && this.state.data.phone === "") {
      this.setState({
        valid: false,
        error: "Debe insertar un número de celular o teléfono",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.address === ""
    ) {
      this.setState({
        valid: false,
        error:
          "La dirección no es válida",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.data.zipcode === "" ||
      !this.state.expressiones.zipCode.test(this.state.data.zipcode)
    ) {
      this.setState({
        valid: false,
        error: "Debe ingresar un código postal de 5 dígitos.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (
      this.state.state === "" ||
      !this.state.expressiones.state.test(this.state.state)
    ) {
      this.setState({
        valid: false,
        error: "Debe ingresar un estado válido.",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    if (this.state.data.paymethod === "CREDIT-CARD") {
      if (
        this.state.data.nameoncard === "" ||
        !this.state.expressiones.nameCard.test(this.state.data.nameoncard)
      ) {
        this.setState({
          valid: false,
          error: "El nombre en la tarjeta debe tener de 10 a 20 letras",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }

      if (
        this.state.data.creditcarnumber === ""
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de tarjeta válido.",
        });

        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (this.state.data.securitycode === "") {
        this.setState({
          valid: false,
          error:
            "Debe ingresar el código de seguridad.",
        });
        return false;
      }else if(this.state.data.creditcarnumber.substring(0, 1) != "3"){
        if (!this.state.expressiones.securityCode.test(this.state.data.securitycode)) {
          this.setState({
            valid: false,
            error:
              "El código de seguridad solo puede contener un máximo de 3 dígitos.",
          });
          return false;
        }else {
          this.setState({
            valid: true,
            error: "",
          });
        }
      }else if(this.state.data.creditcarnumber.substring(0, 1) == "3"){
        if (!this.state.expressiones.securityCode2.test(this.state.data.securitycode)) {
          this.setState({
            valid: false,
            error:
              "El código de seguridad solo puede contener un máximo de 4 dígitos.",
          });
          return false;
        }else {
          this.setState({
            valid: true,
            error: "",
          });
        }
      }else {
        this.setState({
          valid: true,
          error: "",
        });
      }

      
      if (
        this.state.data.expDateM === "" || parseInt(this.state.res.month) > parseInt(this.state.data.expDateM) && parseInt(this.state.res.year) >= parseInt(this.state.data.expDateY) || this.state.data.expDateM > '12'
      ) {
        this.setState({
          valid: false,
          error: "Debe ingresar una fecha de expiración válida.",
        });

        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (
        this.state.data.expDateY === "" || parseInt(this.state.res.year) > parseInt(this.state.data.expDateY) || parseInt(this.state.data.expDateY) > (parseInt(this.state.res.year) + 46)
      ) {

        this.setState({
          valid: false,
          error: "Debe ingresar una fecha de expiración válida.",
        });

        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
    }
    else if (this.state.data.paymethod === "BANK-ACCOUNT") {
      if (this.state.data.accountNameBank === "" || !this.state.expressiones.nameCount.test(this.state.data.accountNameBank)) {
        this.setState({
          valid: false,
          error: "El nombre en la tarjeta debe tener de 10 a 40 letras",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (this.state.data.accountNumberBank === "" || !this.state.expressiones.accountNumber.test(this.state.data.accountNumberBank)) {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de cuenta válido.",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
      if (this.state.data.routeNumberBank === "" || !this.state.expressiones.routeNumber.test(this.state.data.routeNumberBank)) {
        this.setState({
          valid: false,
          error: "Debe ingresar un número de ruta válido",
        });
        return false;
      } else {
        this.setState({
          valid: true,
          error: "",
        });
      }
    }

    if (this.state.mc === "") {
      this.setState({
        valid: false,
        error: "Debe insertar un certificado de registro de comerciante",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }

    if (!this.state.data.terms) {
      this.setState({
        valid: false,
        error: "Aceptar términos y condiciones",
      });
      return false;
    } else {
      this.setState({
        valid: true,
        error: "",
      });
    }
    return true;
  };

  /**
   * Metodo utilizado para registrar un negocio
   * @param {*} e evento submit
   */
  handleSubmit = (e) => {
    e.preventDefault();

    if (this.validation()) {
      this.setState({ loading: true, validForm: true });
      const token = localStorage.getItem("loggedIvuUser")
      this.calculateTotal()

      /* const config = {
        method: 'post',
        url: global.URLBack + `/dashBoard/index/addBusiness`,
        headers: {
          'Authorization': 'Bearer ' + token,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(this.state.data),
        withCredentials: true
      } */


      const formDataCV = new FormData();

      formDataCV.append("CV", this.state.void);

      const ChequeVoidConfig = {
        method: 'post',
        url:
          global.URLBack +
          `/dashBoard/business/saveFormCV/`,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formDataCV
      }

      const formData = new FormData();

      formData.append("SC", this.state.sc);
      formData.append("MC", this.state.mc);

      if (this.state.data.paymethod === "BANK-ACCOUNT") {
        //Primero debe enviar el archivo void y despues guardar el negocio
        axios(ChequeVoidConfig).then(response => {
          if (response) {
            let id_cv = response.data.id_cv
            this.setState((prevState) => ({
              data: { ...prevState.data, id_cv: id_cv, amount: this.state.totalAmount }
            }), () => {

              const config = {
                method: 'post',
                url: global.URLBack + `/dashBoard/index/addBusiness`,
                headers: {
                  'Authorization': 'Bearer ' + token,
                  "Content-Type": "application/json",
                },
                data: this.state.data,
                withCredentials: true
              }


              axios(config)
                .then(res => {
                  const files = {
                    method: "post",
                    url:
                      global.URLBack +
                      `/dashBoard/business/saveForms/` + res.data.idAddress,          //AQUI  
                    headers: {
                      "Content-Type": "multipart/form-data",
                      'Authorization': 'Bearer ' + token,
                    },
                    data: formData,
                    withCredentials: true,
                  };
                  axios(files)
                    .then((res) => {
                      window.history.back()
                      Swal.fire({
                        title: "Negocio registrado exitosamente",
                        icon: "success"
                      })
                    })
                    .catch((err) => {
                      this.setState({
                        loading: false,
                        validForm: false,
                        error: "Ha ocurrido un error en el registro, inténtelo de nuevo"
                      })
                      Swal.fire({
                        title: "Ocurrio un error al registrar el negocio",
                        icon: "error"
                      })
                    });
                }).catch(err => {
                  if (err.response.data.msg) {
                    this.setState({ loading: false, validForm: false, error: err.response.data.msg })
                  }
                  else {
                    this.setState({ loading: false, validForm: false, error: "Ha ocurrido un error en el registro" })
                  }

                })
            });
          }
        })
      } else {
        //Guarda el negocio normal
        const config = {
          method: 'post',
          url: global.URLBack + `/dashBoard/index/addBusiness`,
          headers: {
            'Authorization': 'Bearer ' + token,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(this.state.data),
          withCredentials: true
        }

        axios(config)
          .then(res => {
            const files = {
              method: "post",
              url:
                global.URLBack +
                `/dashBoard/business/saveForms/` + res.data.idAddress,          //AQUI  
              headers: {
                "Content-Type": "multipart/form-data",
                'Authorization': 'Bearer ' + token,
              },
              data: formData,
              withCredentials: true,
            };
            axios(files)
              .then((res) => {

                if (this.state.isSaveToken===true) {
                  let paymentInfo = {
                    nameOnCard: this.state.data.nameoncard,
                    creditcarnumber: this.state.data.creditcarnumber,
                    securitycode: this.state.data.securitycode,
                    cardType: this.state.data.cardtype,
                    expDateMonth: this.state.data.expDateM,
                    expDateYear: this.state.data.expDateY.slice(2),
                    merchantId: this.state.data.merchantnumber,
                    zipCode: '',
                    usingAutomaticPayment: false
                  }

                  let config = {
                    method: 'post',
                    url: global.URLBack + `/query/business/token`,
                    headers: {
                        'Authorization': 'Bearer ' + this.state.token
                    },
                    withCredentials: true,
                    data: paymentInfo
                  }

                  this.setState({ loading: true })
                  axios(config).then(res => {
                    this.setState({ loading: false })
                    window.history.back()
                    Swal.fire({
                      title: "Negocio registrado exitosamente",
                      icon: "success"
                    })
                  }).catch(error => {
                      this.setState({
                        loading: false,
                        validForm: false,
                        error: "Ha ocurrido un error en el registro, inténtelo de nuevo"
                      })
                      Swal.fire({
                        title: "Ocurrió un error al intentar realizar la creación del token",
                        icon: "error"
                      })
                  })
                }else{
                  window.history.back()
                  Swal.fire({
                    title: "Negocio registrado exitosamente",
                    icon: "success"
                  })
                }
              })
              .catch((err) => {
                this.setState({
                  loading: false,
                  validForm: false,
                  error: "Ha ocurrido un error en el registro, inténtelo de nuevo"
                })
                Swal.fire({
                  title: "Ocurrio un error al registrar el negocio",
                  icon: "error"
                })
              });
          }).catch(err => {
            this.setState({ loading: false, validForm: false, error: "Ha ocurrido un error en el registro" })

          })
      }

    }
    else {
      this.setState({ validForm: false });
    }
  };

  getService(serviceName) {
    return this.state.res.service.filter(service => service.serviceName == serviceName).map(service => service.serviceValue)
  }

  /**
   * Metodo utilizado para asignar el valor del servicio y el monto total buscando el servicio que tiene asociado ese cliente en la lista de 
   * servicios que se obtienen del back y así asociar un valor
   * @param {*} serviceInitial 
   */
  setService(serviceInitial) {
    this.state.res.service.filter(service => service.serviceName === serviceInitial).map(service => {
      let data = this.state.data
      data.fiscalterminals = 1
      this.setState({
        data: data
      },()=>{
        let total = (service.serviceValue) + (this.state.additionalTerminal * (this.state.data.fiscalterminals - 1))
        if (this.state.data.fiscalterminals === 0) {
          total = (service.serviceValue)
        }
        this.setState((prevState) => {
          const objetoActual = { ...prevState.data };
          objetoActual.amount = total;
          return { data: objetoActual };
        });
        
        this.setState({
          serviceValue: (service.serviceValue),
          totalAmount: total,
          additionalTerminal: 0
        })
      })
    })
  }

  /**
   * Metodo utilizado para calcular la cuenta total a pagar por un usuario
   */
  calculateTotal() {
    let total = (this.state.serviceValue) + (this.state.additionalTerminal * (this.state.data.fiscalterminals - 1))
    if (this.state.data.fiscalterminals === 0) {
      total = (this.state.serviceValue)
    }
    this.setState((prevState) => {
      const objetoActual = { ...prevState.data };
      objetoActual.amount = total;
      return { data: objetoActual };
    });
    this.setState({
      totalAmount: total
    })
  }

  /**
   * Metodo utilizado para actualizar los valores de los campos ingresados dentro del formulario
   * @param {*} e evento del input
   */
  handleChange = (e) => {

    let value = e.target.type === "checkbox" ? e.target.checked : e.target.value

    if (e.target.name === "automaticPay" || e.target.name === "onePay") {
      if (e.target.name === "automaticPay") {
        this.setState({
          automaticPay: value,
          onePay: !value,
        }, () => {
          this.setState((prevState) => ({
            data: { ...prevState.data, recurringPayments: true }
          }));

        });
      } else {
        this.setState({
          onePay: value,
          automaticPay: !value,
        }, () => {
          this.setState((prevState) => ({
            data: { ...prevState.data, recurringPayments: false }
          }));
        });
      }
      this.setState({
        [e.target.name]: value,
      });
    } else {
      if (e.target.name === "service") {
        this.setService(value)
      }

      this.setState({
        data: {
          ...this.state.data,
          [e.target.name]: value,
        },

      }, () => {
        if (e.target.name === "fiscalterminals") {
          this.calculateTotal()
        }
      });
    }


    if (e.target.name === "merchantnumber") {
      const config = {
        method: 'get',
        url: global.URLBack + `/query/register/checkMerchantNumber/` + e.target.value,
        headers: {
          'Content-Type': 'text/plain'
        },
      }

      axios(config)
        .then(res => {
          if (res.data.res === false) {
            this.setState({
              validForm: false,
              error: "Número de registro de comerciante existente"
            })
          }
          else {
            this.setState({
              validForm: null,
              error: ""
            })
          }

        }).catch(err => {

        })
    }

    if (e.target.name === 'creditcarnumber') {
      switch (e.target.value.substring(0, 1)) {
        case "6":
          this.setState({
            data: {
              ...this.state.data,
              cardtype: "Discover",
              creditcarnumber: e.target.value,
            },
          })
          this.setState({ mask: "9999-9999-9999-9999", maskCvv2: "999" });
          break;
        case "5":
          this.setState({
            data: {
              ...this.state.data,
              cardtype: "Master Card",
              creditcarnumber: e.target.value,
            },
          })
          this.setState({ mask: "9999-9999-9999-9999", maskCvv2: "999" })
          break;
        case "4":
          this.setState({
            data: {
              ...this.state.data,
              cardtype: "Visa",
              creditcarnumber: e.target.value,
            },
          })
          this.setState({ mask: "9999-9999-9999-9999", maskCvv2 : "999" })
          break;
        case "3":
          this.setState({
            data: {
              ...this.state.data,
              cardtype: "American Express",
              creditcarnumber: e.target.value,
            },
          })
          this.setState({ mask: "9999-999999-99999", maskCvv2: "9999" })
          break;
        default:
      }
    }
    if (e.target.name === "paymethod") {
      if (e.target.value === "CREDIT-CARD" || e.target.value === "ATHMOVIL") {
        this.setState({
          sc: "",
          void: "",
          routeNumberBank: "",
          accountNumberBank: "",
          id_cv: 0
        })
      }
      if (e.target.value !== "CREDIT-CARD") {
        this.setState({
          cardtype: "",
          creditcarnumber: "",
          expDateM: "",
          expDateY: "",
          nameoncard: "",
          securitycode: ""
        })
      }
    }
    if (e.target.name === "fiscalterminals") {
      let valueAdditionalTerminal = 0
      if (Number(e.target.value) > 1 && Number(e.target.value) <= 5) {
        valueAdditionalTerminal = (Number(e.target.value) - 1) * Number(this.state.valuesAdditionalTerminal.terminals2to5)
      } else if (Number(e.target.value) > 5 && Number(e.target.value) <= 9) {
        valueAdditionalTerminal = (Number(e.target.value) - 1) * Number(this.state.valuesAdditionalTerminal.terminals6to9)
      } else if (Number(e.target.value) >= 10) {
        valueAdditionalTerminal = (Number(e.target.value) - 1) * Number(this.state.valuesAdditionalTerminal.terminals10)
      }

      let totalAmount = Number(this.getService(this.state.data.service)) + Number(valueAdditionalTerminal)

      this.setState({ additionalTerminal: valueAdditionalTerminal, totalAmount: totalAmount })
    }

    if (e.target.name === "service") {
      //let totalAmount = Number(this.getService(e.target.value)) + Number(this.state.additionalTerminal)
      //this.setState({ totalAmount: totalAmount })
    }
  };

  /**
   * Metodo utilizado para almacenar el archivo que se está cargando en un atributo
   * @param {*} e evento del input file
   */
  handleFiles = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0]
    })
  }

  handleChangeCardNumber = () => {
    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        securitycode: '', 
      },
    }))
  };

  handleChangeSaveToken = (e) => {
    const { name, checked } = e.target;
    this.setState({ [name]: checked }); 
  }

  /**
  * Metodo de renderizado
  * @returns html que contiene el formulario de registro
  */
  render() {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, i) => currentYear + i); // Opciones de años próximos

    return (
      <div>
        <header>
          <Navbar />
        </header>

        <main>
          <div className="son2">
            <div>
              <Titulo>Registrar Negocio</Titulo>
            </div>
            <div>
              <Breadcrumbs
                className="breadcrumbs"
                separator=">"
                aria-label="breadcrumb"
              >
                <Link className="link-rp" color="inherit" to="/dashboard">
                  <FontAwesomeIcon className="icon" icon={faTachometerAlt} />
                  Panel
                </Link>
                <Link className="link-rp" color="inherit" to="/dashboard/businessRegister">
                  Registrar Negocio
                </Link>
              </Breadcrumbs>
            </div>
          </div>
          {this.state.loading ? <div className="spinner"></div> : null}
          <div className={this.state.loading ? "son-hidden" : "son"}>
            <Formn action="" >

              <Label>
                Nombre del Negocio *
                <Input
                  onChange={this.handleChange}
                  name="bussinessname"
                  type="text"
                  placeholder="Nombre del Negocio"
                />
              </Label>
              <Label>
                Número de registro del Comerciante *
                <InputMask className="inputMask"
                  onChange={this.handleChange}
                  name="merchantnumber"
                  type="text"
                  mask="99999999999"
                  placeholder="Registro del Comerciante"
                />
              </Label>

              <Label>
                Teléfono movil *
                <InputMask className="inputMask"
                  onChange={this.handleChange}
                  name="mobilephone"
                  type="text"
                  mask="999-999-9999"
                  placeholder="Número de teléfono móvil"
                />
              </Label>
              <Label>
                Teléfono
                <InputMask className="inputMask"
                  onChange={this.handleChange}
                  name="phone"
                  type="text"
                  mask="999-999-9999"
                  placeholder="Número de teléfono"
                />
              </Label>
              <Label>
                Dirección *
                <Input
                  onChange={this.handleChange}
                  name="address"
                  type="text"
                  placeholder="Dirección"
                />
              </Label>

              <Label>
                Linea de Dirección 2
                <Input
                  onChange={this.handleChange}
                  name="address2"
                  type="text"
                  placeholder="Dirección"
                />
              </Label>
              <Label>
                Ciudad/ Estado/ Código Postal *
                <div className="DivForm">
                  <SelectT onChange={this.handleChange} name="town">
                    {this.state.res.towns.map(town => (
                      <option key={town.id} value={town.id}>
                        {town.value}
                      </option>
                    ))}
                  </SelectT>

                  <InputState
                    onChange={this.handleChange}
                    placeholder="Estado"
                    name="state"
                    value="PR"
                    disabled
                  />

                  <InputZip
                    onChange={this.handleChange}
                    placeholder="Código Postal"
                    name="zipcode"
                  />
                </div>
              </Label>
              <Label>
                Servicio / Terminales Físicas *
                <div>
                  <FontAwesomeIcon icon={faShoppingBasket}></FontAwesomeIcon>

                  <SelectService onChange={this.handleChange}
                    name="service">
                    {this.state.res.service.filter(s => s.active === true).map((s) => (
                      <option key={s.serviceId} value={s.serviceName}>
                        {s.serviceName}
                      </option>
                    ))}
                  </SelectService>
                  <SelectPhysiTer
                    onChange={this.handleChange}
                    name="fiscalterminals"
                    value={this.state.data.fiscalterminals}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                  </SelectPhysiTer>
                </div>
              </Label>
              <Label>
                Software *
                <div>
                  <SelectSW onChange={this.handleChange} name="software">
                    {this.state.res.software.map((Software) => (
                      <option key={Software.id} value={Software.value}>
                        {Software.value}
                      </option>
                    ))}
                  </SelectSW>
                </div>
              </Label>
              <br></br>
              <h3> <u>INFORMACIÓN PARA PAGO</u> </h3><br></br>
              <Label>
                Método de pago
                <SelectPM onChange={this.handleChange} name="paymethod" value={this.state.data.paymethod}>
                  {this.state.paymentMethods.filter(method => method.status === true).map((method, index) => (
                    <option key={index} value={method.code}>{method.name}</option>
                  ))
                  }
                </SelectPM>
                {this.state.data.paymethod === "ATHMOVIL" ? (
                  <div id="form-group-ath-movil">
                    <label className="QR_text">
                      Escanea el código QR para proceder con tu pago.
                    </label>
                    <label>
                      <img width="400vw" height="500vh" src={QR} alt="QR de Ath Movil" />
                    </label>
                  </div>
                ) : null}
              </Label>
              <br></br>


              {this.state.data.paymethod === "CREDIT-CARD" ? (
                <div id="form-group-credit-card">
                  <Label>
                    Nombre en la tarjeta *
                    <Input
                      onChange={this.handleChange}
                      name="nameoncard"
                      type="text"
                      placeholder="Nombre en la tarjeta"
                    />
                  </Label>
                  <Label>
                    Número Tarjeta Crédito/ Tipo de tarjeta *
                    <div className="DivForm">
                      <InputMask
                        className="inputCcNumber"
                        onChange={this.handleChange}
                        onBlur={this.handleChangeCardNumber}
                        name="creditcarnumber"
                        type="text"
                        placeholder="Número"
                        mask={this.state.mask}
                      />
                      <Selectll
                        onChange={this.handleChange}
                        name="cardtype"
                        value={this.state.data.cardtype}
                        disabled
                      >
                        {this.state.res.typeCard.map((s) => (
                          <option key={s.value} value={s.value}>
                            {s.value}
                          </option>
                        ))}
                      </Selectll>
                    </div>
                  </Label>
                  <Label>
                    Código de seguridad CVV2 *
                    <InputMask
                      className="inputMask"
                      onChange={this.handleChange}
                      name="securitycode"
                      type="text"
                      mask={this.state.maskCvv2}
                      placeholder="Código de seguridad"
                      value={this.state.data.securitycode}
                    />
                  </Label>
                  <Label>
                    Fecha de Expiración - Mes/Año *
                    <div>
                      <select
                        name="expDateM"
                        value={this.state.expMonth}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="">
                          MM
                        </option>
                        {Array.from({ length: 12 }, (_, i) => (
                          <option key={i + 1} value={String(i + 1).padStart(2, "0")}>
                            {String(i + 1).padStart(2, "0")}
                          </option>
                        ))}
                      </select>
                      /
                      <select
                        name="expDateY"
                        value={this.state.expYear}
                        onChange={this.handleChange}
                        required
                      >
                        <option value="">
                          YYYY
                        </option>
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Label>
                  <Form.Check
                    style={{ marginLeft: "10px" }}
                    type="checkbox"
                    id="one-check"
                    name="isSaveToken"
                    label="Guardar medio de pago"
                    checked={this.state.isSaveToken}

                    onChange={this.handleChangeSaveToken}
                  />
                </div>
              ) : null}
              {this.state.data.paymethod === "BANK-ACCOUNT" ? (
                <div id="form-group-debitcard">
                  <Label>
                    Nombre de cuenta *
                    <Input
                      onChange={this.handleChange}
                      name="accountNameBank"
                      type="text"
                      placeholder="Nombre de cuenta"
                    />
                  </Label>
                  <Label>
                    Número de cuenta *
                    <InputMask className="inputMask"
                      onChange={this.handleChange}
                      name="accountNumberBank"
                      type="text"
                      placeholder="Número de cuenta"
                      mask="9999999999999999"
                    />
                  </Label>
                  <Label>
                    Número de ruta *
                    <InputMask className="inputMask"
                      onChange={this.handleChange}
                      name="routeNumberBank"
                      type="text"
                      placeholder="Número de ruta"
                      mask="999999999"
                    />
                  </Label>
                </div>
              ) : null}

              <div style={{ marginLeft: "2%" }}>
                <p><strong>ANUALIDAD + 1 TERMINAL: ${this.state.serviceValue} </strong> </p>
                <p><strong>TERMINALES ADICIONALES: {this.state.data.fiscalterminals - 1} x ${this.state.additionalTerminal}</strong></p>
                <p style={{ marginLeft: "80px" }}><strong>TOTAL DE PAGO: $ {this.state.totalAmount}</strong></p>

              </div>
              {this.state.data.paymethod === "ATHMOVIL" ?

                <div>
                  <p>1. Verifique a la izquiera el saldo de la cuenta</p>
                  <p>2. Buscanos como negocio utilizando la información de ATHMovil</p>
                  <p>3. En la descripción por favor indicar "<strong>Nombre de Negocio</strong>"</p>
                  <p>4. Una vez el pago es completado marque el encasillado</p>
                  <p>5. Acepte los términos y condiciones</p>
                  <p>6. Luego presione el boton de "aceptar y pagar"</p>
                  <p>7. El pago se estará verificando y despues aplicando a su cuenta</p>
                </div>

                : null}

              <Label>
                Certificado de Registro de Comerciante *
                <div className="div-form-bi">
                  <input
                    type="file"
                    id="file2"
                    name="mc"
                    placeholder="Seleccionar archivo"
                    onChange={this.handleFiles}
                  />{" "}
                </div>
                {/*this.state.formMC ?<div> <label><b>Archivo existente</b></label>
           <p>Si desea actualizar la Información presione "seleccionar archivo"</p>
             <div className="div-form-bi">
             <input type="file" id="file" name="mc" placeholder="Seleccionar archivo"
             onChange={this.handleChange}/> </div> <div className="row">
               {this.state.mc ? 
           <div className="col-6">

         <button id="btn2-bi" className="btn btn-light" type="button"
         onClick={this
           .submitUpdateMC
           .bind(this)}>Actualizar Certificado de Registro de Comerciante</button>
           </div>
         : null}
            <div className="col-6">
           <button id="btn2-bi" className="btn btn-light" type="button"
           onClick={this.handleDownloadMC.bind(this)}
         >Visualizar Registro de Comerciante</button>
         </div>
           
         </div></div>: <div> <div className="div-form-bi">                         
             <input type="file" id="file2" name="mc" placeholder="Seleccionar archivo"
             onChange={this.handleOnFileChange}/>
         </div>
         <div className="row">
           {this.state.mc ? <div className="col-7">

<button id="btn2-bi" className="btn btn-light" type="button"
onClick={this
.submitLoginMC
.bind(this)}>Subir Certificado de Registro de Comerciante</button>
</div> : null }
           
</div> </div>*/}
              </Label>
              {this.state.data.paymethod === "CREDIT-CARD" && <br></br>}
              {this.state.data.paymethod === "BANK-ACCOUNT" &&
                <Label>
                  Cheque Void *
                  <div className="div-form-bi">
                    <input
                      type="file"
                      id="file1"
                      name="void"
                      placeholder="Seleccionar archivo"
                      onChange={this.handleFiles}
                    />
                  </div>
                </Label>

              }
              {/*        {this.state.data.paymethod == "BANK-ACCOUNT" && this.state.onePay && */}
              <React.Fragment>
                <div>
                  <Label>
                    Formulario SC2916
                    <div className="div-form-bi">
                      <input
                        type="file"
                        id="file1"
                        name="sc"
                        placeholder="Seleccionar archivo"
                        onChange={this.handleFiles}
                      />
                    </div>
                    {/*this.state.formSC ? <div>
             <label><b>Archivo existente</b></label>
             <p>Si desea actualizar la información presione "seleccionar archivo"</p>
             <div className="div-form-bi">
             
             <input type="file" id="file" name="sc" placeholder="Seleccionar archivo"
             onChange={this.handleOnFileChange}/>
         </div>
             <div className="row">

               {this.state.sc ?
             <div className="col-5">
             <button id="btn1-bi" className="btn btn-light" type="button"
             onClick={this
               .submitUpdateSC
               .bind(this)}>Actualizar formulario SC2916</button>
               </div>
               : null}
             <div className="col-6">
             <button id="btn1-bi" className="btn btn-light" 
             onClick={this.handleDownloadSC.bind(this)}
         >Visualizar formulario SC2916</button>
             </div>
             </div>
           </div>
           : <div>
             <div className="div-form-bi">                            
             <input type="file" id="file" name="sc" placeholder="Seleccionar archivo"
             onChange={this.handleOnFileChange}/>
         </div>
         <div className="row">
         {this.state.sc ? <div className="col-5">
         <button id="btn1-bi" className="btn btn-light" type="button"
         onClick={this
           .submitLogin
           .bind(this)}>Subir formulario SC2916</button>

         </div> : null }
         
           
           </div>
         </div>*/}

                  </Label>
                  <small>(CERTIFICADO DE COMPRAS EXCENTAS Y DE SERVICIOS SUJETOS AL IVU-ESPECIAL DE 4%)
                    Por favor  <a href="https://ivucontrolpr.com/images/Formulario%20SC_2916_2015_1.pdf" target="_blank" rel="noreferrer"><u>Descargar</u></a>, Completar, Firmar y Cargar</small>
                </div>

                <div></div>
              </React.Fragment>


              {this.state.data.paymethod !== "ATHMOVIL" ?
                <div>

                  <Form.Check
                    style={{ marginLeft: "10px" }}
                    type="checkbox"
                    id="one-check"
                    name="onePay"
                    label="Pago por saldo actual"
                    checked={this.state.onePay}

                    onChange={this.handleChange}
                  />
                  <br></br>
                  <Form.Check
                    style={{ marginLeft: "10px" }}
                    type="checkbox"
                    id="auto-check"
                    name="automaticPay"
                    checked={this.state.automaticPay}

                    label="Pago por saldo actual y pagos siguientes automatizados"
                    onChange={this.handleChange}
                  />
                </div> : null
              }

              {this.state.automaticPay && (this.state.data.paymethod === "BANK-ACCOUNT" || this.state.data.paymethod === "CREDIT-CARD") ?
                <p>Entiende que estás aceptando que en tu cuenta bancaria se realice el
                  cobro anual del servicio de IVUControl de manera automática cada año, a menos que
                  decidas cancelar el servicio. Es importante recordar que cualquier acuerdo financiero
                  o contrato debe ser leido y comprendido completamente antes de aceptarlo. Si tienes alguna
                  pregunta o inquietud sobre los términos y condiciones de servicio o los aspectos financieros
                  relacionados, te recomiendo que te pongas en contacto directamente con el proveedor del servicio o
                  con tu entidad bancaria para obtener más detalles y aclaraciones.

                </p> :
                <React.Fragment>
                  {this.state.data.paymethod === "ATHMOVIL" ?
                    <p>Entiende que está aceptando los terminos y condiciones. Es importante recordar que cualquier acuerdo financiero
                    o contrato debe ser leido y comprendido completamente antes de aceptarlo. Si tienes alguna
                    pregunta o inquietud sobre los términos y condiciones de servicio o los aspectos financieros
                    relacionados, te recomiendo que te pongas en contacto directamente con el proveedor del servicio o
                    con tu entidad bancaria para obtener más detalles y aclaraciones.</p>:
                    <p>Es importante recordar que cualquier acuerdo financiero
                    o contrato debe ser leido y comprendido completamente antes de aceptarlo. Si tienes alguna
                    pregunta o inquietud sobre los términos y condiciones de servicio o los aspectos financieros
                    relacionados, te recomiendo que te pongas en contacto directamente con el proveedor del servicio o
                    con tu entidad bancaria para obtener más detalles y aclaraciones.</p>

                  }
                  
                  <small><strong>*Recuerde que es su responsabilidad hacer el pago de renovación de servicio, para asistirle
                    con esto se estarán enviando tres correos electrónicos de aviso, 30 días antes, al vencer y 15 días despúes de vencer.
                    Es responsabilidad del cliente pagar a tiempo para evitar interrupción en el servicio.</strong></small>
                </React.Fragment>

              }


              <ContainerTerms>
                <Label>
                  <input
                    type="checkbox"
                    name="terms"
                    onClick={this.handleChange}
                    defaultChecked={this.state.terms}
                  />
                  <Link to="/terms">Acepto los términos y condiciones de uso</Link>

                </Label>
              </ContainerTerms>

              {this.state.validForm === false ? <MsjFail id="error" >
                <p className="error-message" >
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <b>{this.state.error}</b>
                </p>
              </MsjFail> : null}

              <ContainerButtonCA>

                <Button type="button" variant="danger" className="button2" href="/dashboard"> Cancelar</Button>

                <Buttonn type="button"
                  onClick={this.handleSubmit.bind(this)}>Aceptar y Pagar</Buttonn>

              </ContainerButtonCA>


            </Formn>
          </div>
        </main>
        <footer>© 2021 IvuControlPR</footer>
      </div>
    );
  }
}
export default withRouter(Register);