import React, { useState } from "react";

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      {items.map((item, index) => (
        <div key={index} style={{ marginBottom: "10px", border: "1px solid #ccc" }}>
          <div
            style={{
              background: "#f5f5f5",
              padding: "10px 15px",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={() => toggleAccordion(index)}
          >
            {item.title}
          </div>
          {activeIndex === index && (
            <div style={{ padding: "10px 15px", background: "#fff" }}>
              {item.content}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;
