import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import React from "react";
import Button from 'react-bootstrap/Button';
import '../elements/UserDashboard.css'
import {
    Input,
    Selectll,
    Label,
    MsjFail,
    SelectPM
} from "../elements/Forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import QR from "../img/athm_qrcode_sticker.png"
import InputMask from 'react-input-mask';
import axios from 'axios';
import Swal from 'sweetalert2';
import Accordion from './Accordion';

/**
 * Componente que representa el modal de realizar pago o reactivar negocio
 */
class PayBillMerchant extends React.Component {
    constructor(props) {
        super(props);
        const fechaActual = new Date();
        this.state = {
            showPayModal: false,
            validated: false,
            merchantID: props.merchant,
            validForm: true,
            buttonName: props.buttonName,
            paymethod: "CREDIT-CARD",
            totalAmount: 0,
            additionalTerminal: 99,
            fiscalterminals: 0,
            serviceValue: 99,
            recurringPayments: false,
            zipcode: "",
            typeCard: [
                {
                    "id": 1,
                    "value": "Visa"
                },
                {
                    "id": 2,
                    "value": "Master Card"
                },
                {
                    "id": 3,
                    "value": "American Express"
                },
                {
                    "id": 4,
                    "value": "Discover"
                }
            ],
            error: "",
            expressiones: {

                nameCard: /^[a-zA-ZÀ-ÿ\s]{10,40}$/,
                nameCount: /^.+/,
                securityCode: /^\d{3}$/,
                securityCode2: /^\d{4}$/,

                state: /^[a-zA-ZÀ-ÿ\s]{3,10}$/,
                routeNumber: /^\d{4,9}$/,
                accountNumber: /^\d{4,16}$/,
            },
            month: fechaActual.getMonth() + 1,
            year: fechaActual.getFullYear(),
            loading: false,
            automaticPay: false,
            onePay: true,
            expDateM: "",
            expDateY: "",
            accountNameBank: "",
            accountNumberBank: "",
            routeNumberBank: "",
            cardtype: "",
            nameoncard: "",
            id_cv: 0,
            service: "",
            void: "",
            loading2: false,
            idtown: 0,
            bussinessname: props.bussinessname,
            rejected: [],
            totalComplete: 0,
            paymentMethods: [],
            bussiness: props.bussiness,
            selectedBusiness: "",
            user: props.user,
            usingAutomaticPayment:false,
            infoToken: {},
            nameOnCard: "",
            creditcarnumber: "",
            securitycode:"",
            cardType: "",
            expDateMonth: "",
            expDateYear: "",
            merchantId: "",
            zipCode: "",
            mask: "9999-9999-9999-9999",
            maskCvv2: "999"
        };
    }

    /**
     * Se obtiene el token del usuario
     */
    componentDidMount() {
        /*    const additionalTer = this.props.location.state.additional;
           this.setState({
               additionalTerminal: additionalTer
           }) */
        const token = localStorage.getItem('loggedIvuUser')
        this.setState({
            token: token
        })

        if (this.state.bussiness && this.state.bussiness.length > 0) {
          this.setState({ loading: true })
          this.setState(
            { selectedBusiness: this.state.bussiness[0].MerchantID },
            () => {
              this.loadingToken()
            }
          );
          
        }
    }

    async loadingToken(){
      const configExistPaymentToken = {
        method: 'get',
        url: global.URLBack + `/query/business/${this.state.selectedBusiness}/token/exist`,
        headers: {
            'Authorization': 'Bearer ' + this.state.token
        },
        withCredentials: true
      }
      
      this.setState({ loading: true })
      await axios(configExistPaymentToken)
          .then(res => {
              this.setState({ loading: false })
              this.setState(
                {exisToken: res.data},
                () => {
                  if (this.state.exisToken===true) {
                    this.loadingInfoToken()
                  }
                }
              )

          }).catch(err => {
              this.setState({ loading: false, error: true })
              localStorage.removeItem('loggedIvuUser')
              //localStorage.removeItem('role')
              window.location.reload()
          })
    }

    async loadingInfoToken(){
      const configPaymentToken = {
        method: 'get',
        url: global.URLBack + `/query/business/${this.state.selectedBusiness}/token`,
        headers: {
            'Authorization': 'Bearer ' + this.state.token
        },
        withCredentials: true
      }

      this.setState({ loading: true })
      await axios(configPaymentToken)
        .then(res => {
            this.setState({
              infoToken: res.data,
              loading: false 
            });
        }).catch(err => {
            this.setState({ loading: false, error: true })
            localStorage.removeItem('loggedIvuUser')
            //localStorage.removeItem('role')
            window.location.reload()
        })
    }

    /**
     * Metodo utilizado para almacenar el archivo que se está cargando en un atributo
     * @param {*} e evento input file
     */
    handleFiles = (e) => {
        this.setState({
            [e.target.name]: e.target.files[0]
        })
    }

    /**
     * Metodo utilizado para desplegar u ocultar el modal y traer la información detallada del servicio para ese negocio
     * @param {*} value Si es True entonces se despliega el modal, en caso contrario se oculta
     */
    payModal = (value) => {
        
        this.setState({
            loading: true
        })
        if (value) {
            this.setState({
                loading: false
            })
            /*
            let config = {
                method: 'get',
                url: global.URLBack + `/dashBoard/business/service/` + this.state.merchantID,
                headers: {
                    'Authorization': 'Bearer ' + this.state.token
                },
                withCredentials: true
            }
            axios(config).then(res => {
                const config2 = {
                    method: 'get',
                    url: global.URLBack + `/dashBoard/superUser/paymentMethods`,
                }

                axios(config2).then(response2 => {
             
                    this.setState({ paymentMethods: response2.data })
                }).catch(error => {
                    this.setState({ loading: false })
                    Swal.fire({
                        title: "Ha ocurrido un error, por favor intentelo más tarde",
                        icon: "error"
                    })
                    this.setState({
                        showPayModal: false
                    })
                })

                this.setState({ loading: false })
                if (res !== undefined) {
                 
                    let terminalesAdicionales = res.data.cantidadTerminales - 1
                    if (terminalesAdicionales < 0) {
                        terminalesAdicionales = 0
                    }
                    let rejected = []
                    res.data.historialPagos.forEach(element => {
                        if (element.rejected === 1 && element.paid === 0) {
                            rejected.push(element)
                        }
                    });

                    let total = 0
                    let additionalTerminals = 0
                    
                    if(res.data.cantidadTerminales>=2 && res.data.cantidadTerminales<=5){
                        additionalTerminals=res.data.terminals2to5
                    }else if(res.data.cantidadTerminales>=6 && res.data.cantidadTerminales<=9){
                        additionalTerminals=res.data.terminals6to9
                    }else if(res.data.cantidadTerminales>=10){
                        additionalTerminals=res.data.terminals10
                    } 

                    if (rejected.length >= 1) {
                        for (let i = 0; i < rejected.length; i++) {
                            total = total + rejected[i].totalAmount + 25
                        }
                    } else {                       
                        
                        total = res.data.serviceValue + (additionalTerminals * terminalesAdicionales)
                    }

                    this.setState({
                        zipcode: res.data.zipcode,
                        service: res.data.service,
                        additionalTerminal: additionalTerminals,
                        fiscalterminals: res.data.cantidadTerminales,
                        serviceValue: res.data.serviceValue,
                        totalAmount: total,
                        idtown: res.data.idtown,
                        rejected: rejected
                    })
                }
            }).catch(err => {
                this.setState({ loading: false })
                Swal.fire({
                    title: "Ha ocurrido un error, por favor intentelo más tarde",
                    icon: "error"
                })
                this.setState({
                    showPayModal: false
                })
            })*/
            
        } else {
            this.setState({
                paymethod: "CREDIT-CARD"
            })
        }
        this.setState({
            showPayModal: value
        })
    }

    /**
     * Metodo utilizado para validar el formulario de pago
     * @returns True si los valores de los campos son válidos, False en caso contrario
     */
    validation() {
      if(this.state.nameOnCard === "" || !this.state.expressiones.nameCard.test(this.state.nameOnCard)) {
        this.setState({
          validForm: false,
          error: "El nombre en la tarjeta debe tener de 10 a 20 letras"
        });
        return false;
      } else {
        this.setState({
          validForm: true,
          error: ""
        });
      }

      if (this.state.creditcarnumber === undefined || this.state.creditcarnumber === "") {
        this.setState({
            validForm: false,
            error: "Debe ingresar un número de tarjeta válido.",
        });

        return false;
      } else {
        this.setState({
            validForm: true,
            error: "",
        });
      }

      if (this.state.securitycode === "") {
        this.setState({
          validForm: false,
          error:
            "Debe ingresar el código de seguridad.",
        });
        return false;
      }else if(this.state.creditcarnumber.substring(0, 1) != "3"){
        if (!this.state.expressiones.securityCode.test(this.state.securitycode)) {
          this.setState({
            validForm: false,
            error:
              "El código de seguridad solo puede contener un máximo de 3 dígitos.",
          });
          return false;
        }else {
          this.setState({
            validForm: true,
            error: "",
          });
        }
      }else if(this.state.creditcarnumber.substring(0, 1) == "3"){
        if (!this.state.expressiones.securityCode2.test(this.state.securitycode)) {
          this.setState({
            validForm: false,
            error:
              "El código de seguridad solo puede contener un máximo de 4 dígitos.",
          });
          return false;
        }else {
          this.setState({
            validForm: true,
            error: "",
          });
        }
      }else {
        this.setState({
          validForm: true,
          error: "",
        });
      }

      if(this.state.zipCode === "") {
        this.setState({
          validForm: false,
          error: "Debe ingresar el Zip code."
        });
        return false;
      } else {
        this.setState({
          validForm: true,
          error: ""
        });
      }
      
      if (this.state.expDateMonth === undefined || this.state.expDateMonth === "" || parseInt(this.state.month) > parseInt(this.state.expDateMonth) && parseInt(this.state.year) >= parseInt(this.state.expDateYear) || this.state.expDateMonth > '12') {
        this.setState({
            validForm: false,
            error: "Debe ingresar una fecha de expiración válida.",
        });

        return false;
      } else {
        this.setState({
            validForm: true,
            error: "",
        });
      }

      if (
          this.state.expDateYear === undefined || this.state.expDateYear === "" || parseInt(this.state.year) > parseInt(this.state.expDateYear) || parseInt(this.state.expDateYear) > (parseInt(this.state.year) + 46)
      ) {

        this.setState({
            validForm: false,
            error: "Debe ingresar una fecha de expiración válida.",
        });

        return false;
      } else {
        this.setState({
            validForm: true,
            error: "",
        });
      }

      return true
    }

    /**
     * Metodo que actualiza y valida el valor de los atributos del estado de acuerdo a lo ingresado en el formulario
     * @param {*} e evento input
     */
    handleChange = (e) => {
        if (e.target.name === 'selectedBusiness'){
          this.setState(
            { selectedBusiness: e.target.value },
            () => {
              this.loadingToken()
            }
          );
          /*
          this.setState({
            cardtype: "",
            creditcarnumber: "",
            expDateM: "",
            expDateY: "",
            nameoncard: "",
            cardtype: "Visa",
            securitycode: ""
          })*/
        }else if (e.target.name === 'creditcarnumber') {

            switch (e.target.value.substring(0, 1)) {
                case "6":
                  this.setState({
                    cardType: "Discover",
                    creditcarnumber: e.target.value,
                    mask: "9999-9999-9999-9999", 
                    maskCvv2: "999"
                  })
                  break;
                case "5":
                  this.setState({
                    cardType: "Master Card",
                    creditcarnumber: e.target.value,
                    mask: "9999-9999-9999-9999", 
                    maskCvv2: "999"
                  })
                  break;
                case "4":
                  this.setState({
                    cardType: "Visa",
                    creditcarnumber: e.target.value,
                    mask: "9999-9999-9999-9999", 
                    maskCvv2 : "999"
                  })
                  break;
                case "3":
                  this.setState({
                    cardType: "American Express",
                    creditcarnumber: e.target.value,
                    mask: "9999-999999-99999", 
                    maskCvv2: "9999"
                  })
                  break;
                default:
                  this.setState({
                    cardType: "Visa",
                    creditcarnumber: e.target.value,
                    mask: "9999-9999-9999-9999", 
                    maskCvv2 : "999"
                  })
                  break;
            }
        }else if (e.target.name === 'usingAutomaticPayment') {        
          const { name, checked } = e.target;
          this.setState({ [name]: checked }); 
        }
        else{
          const { name, value } = e.target;
          this.setState({ [name]: value }); 
        }
    };

    /**
     * Metodo utilizado para realizar el pago o reactivación de un negocio
     * @param {*} e evento submit
     */
    save = (e) => {
        if (this.validation()) {
            let paymentInfo = {
              nameOnCard: this.state.nameOnCard,
              creditcarnumber: this.state.creditcarnumber,
              securitycode: this.state.securitycode,
              cardType: this.state.cardType,
              expDateMonth: this.state.expDateMonth,
              expDateYear: this.state.expDateYear.slice(2),
              merchantId: this.state.selectedBusiness,
              zipCode: this.state.zipCode,
              usingAutomaticPayment: this.state.usingAutomaticPayment
            }
            
            let config = {
              method: 'post',
              url: global.URLBack + `/query/business/token`,
              headers: {
                  'Authorization': 'Bearer ' + this.state.token
              },
              withCredentials: true,
              data: paymentInfo
            }
            this.setState({ loading: true })
            axios(config).then(res => {
              this.setState({ loading: false })
              if (res) {
                  Swal.fire({
                      title: res.data.message,
                      icon: "success"
                  }).then((result) => {
                      if (result.isConfirmed) {
                        this.cleanData()
                        this.loadingToken()
                      }
                  })
              }
            }).catch(error => {
                this.setState({ loading2: false })
                Swal.fire({
                    title: "Ocurrió un error al intentar realizar la creación del token",
                    icon: "error"
                }).then((response) => {
                  if (response.isConfirmed) {
                    this.payModal(false)
                  } 
                })
            })


        }
    }

    cleanData(){
      this.setState({
        nameOnCard: "",
        creditcarnumber: "",
        securitycode: "",
        cardType: "",
        expDateMonth: "",
        expDateYear: "",
        merchantId: "",
        zipCode: "",
        usingAutomaticPayment: false
      })
    }

    handleChangeCardNumber = () => {
        this.setState(() => ({
          securitycode: ''
        }))
      };



    deleteToken = () => {
      Swal.fire({
          title: 'Eliminar token existente',
          text: 'Para crear un nuevo token se necesita eliminar el token existente, ¿está seguro que desea eliminar el token?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sí, eliminar',
          cancelButtonText: "Cancelar",
          reverseButtons: true
      }).then((result) => {
          if (result.isConfirmed) { 

            const config = {
              method: 'delete',
              url: global.URLBack + `/query/business/` + this.state.selectedBusiness + '/token',
              headers: {
                  'Authorization': 'Bearer ' + this.state.token
              },
              withCredentials: true
            }

            this.setState({ loading: true })
            axios(config).then(res => {
              this.setState({ loading: false })
              if (res.data) {
                Swal.fire({
                  title: "Token eliminado",
                  text: "El token ha sido eliminado correctamente.",
                  icon: "success"
                }).then(res=>{
                  if (res.isConfirmed) {
                    this.setState({exisToken: false})
                    this.cleanData()
                  }
                })
              } else {
                Swal.fire({
                    title: "Ha ocurrido un erro al eliminar el token",
                    icon: "error"
                })
              }
            }).catch(err => {
                Swal.fire({
                    title: "Ha ocurrido un erro al desactivar el negocio",
                    icon: "error"
                })
                this.setState({ loading: false, error: true })
                localStorage.removeItem('loggedIvuUser')
                //localStorage.removeItem('role')
                window.location.reload()
            })
          }
      })
    }



    /**
     * Metodo de renderizado
     * @returns html que contiene el botón que despliega el modal y el modal
     */
    render() {
        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: 10 }, (_, i) => currentYear + i); // Opciones de años próximos

        const items = [
          { title: "¿Qué es un token de pago y para qué se utiliza?", content: "Se habla mucho de seguridad y privacidad de los datos en el ecosistema de los pagos en línea. En un panorama dominado cada vez más por medios y canales de pago digitales, la seguridad de las transacciones es un aspecto prioritario. En este artículo, descubrirás qué es un token de pago o un security token, su importancia y ventajas de su funcionamiento." },
          { title: "¿Qué es tokenizar?", content: "Un token es un valor aleatorio que representa un número de tarjeta y que pasa por la red de forma segura para completar la operación de pago sin exponer los datos de la tarjeta bancaria. Se trata de un sistema que permite sustituir los datos sensibles con un token, es decir, un código cifrado, unívoco e identificativo. De esta forma, ningún dato quedará expuesto durante la transacción, reduciendo la cantidad de sistemas que pueden acceder a la información del titular de la tarjeta y eliminando el riesgo de fraude. Si un usuario efectúa una compra mediante un modo de pago tokenizado, a la red de tarjetas no se enviarán los datos relacionados con la cuenta del titular, sino un código único que evita la duplicación de la información del cliente." },
          { title: "¿Cómo funciona un token de pago?",
            content: (
              <div>
                <p>El funcionamiento de un token de pago es muy sencillo y consta de cuatro fases:</p>
                <ul>
                  <li>
                    <strong>El aprovisionamiento:</strong> el cliente ya posee un token asociado a su número
                    primario de cuenta.
                  </li>
                  <li>
                    <strong>La tokenización:</strong> durante este proceso, el PAN es convertido en token y
                    es almacenado de forma encriptada en la plataforma con la que se va a realizar el pago.
                    De esta manera, los datos reales permanecen protegidos.
                  </li>
                  <li>
                    <strong>La validación:</strong> el token es enviado a la red de tarjetas para el
                    procesamiento de la transacción. La propia red se encarga de descifrarlo para conseguir
                    la información real, enviarla al banco y obtener la aprobación por parte de la entidad
                    financiera.
                  </li>
                  <li>
                    <strong>La autorización:</strong> en cuanto recibe la validación de la transacción, la
                    red de tarjetas vuelve a convertir el número primario de cuenta en token y envía la
                    autorización al vendedor.
                  </li>
                </ul>
                <p>
                  El proceso se realiza en cuestión de segundos y es compatible con los ecosistemas de pagos
                  existentes.
                </p>
              </div>
            ), },
        ];

        return (
            <React.Fragment>

                <Button style={{ backgroundColor: "#3262a8" }} className="d-inline btnW" size="lg" onClick={() => this.payModal(true)}><strong>Token</strong></Button>

                <Modal size='xl' show={this.state.showPayModal} onHide={() => this.payModal(false)} >
                    {this.state.validForm === false ? <MsjFail id="error" >
                        <p className="error-message" >
                            <FontAwesomeIcon icon={faExclamationTriangle} />
                            <b>{this.state.error}</b>
                        </p>
                    </MsjFail> : null}
                    {this.state.loading ?
                        <div className="spinner"></div>
                        :
                        <Form noValidate validated={this.state.validated} id="formPay" >
                            <Modal.Header closeButton>
                                <Modal.Title>TOKEN DE PAGOS</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            {
                              this.state.selectedBusiness!="" ?
                              <div className='row'>
                                <div className='col-12'>

                                    Seleccione el Negocio
                                    <SelectPM onChange={this.handleChange} value={this.state.selectedBusiness} name="selectedBusiness">
                                      {this.state.bussiness // Filtrar negocios activos
                                        .map((business, index) => (
                                          <option key={index} value={business.MerchantID}>
                                            {business.bussinessname}
                                          </option>
                                        ))
                                      }
                                    </SelectPM>

                                    <Label >
                                        {this.state.exisToken===false ? 
                                          <div id="form-group-credit-card">
                                            <Label>
                                                Nombre en la tarjeta *
                                                <Input
                                                    onChange={this.handleChange}
                                                    name="nameOnCard"
                                                    type="text"
                                                    placeholder="Nombre en la tarjeta"
                                                    required
                                                    value={this.state.nameOnCard}
                                                />
                                            </Label>
                                            <Label>
                                                Número Tarjeta Crédito/ Tipo de tarjeta *
                                                <div className="DivForm">
                                                    <InputMask
                                                        className="inputCcNumber"
                                                        onChange={this.handleChange}
                                                        onBlur={this.handleChangeCardNumber}
                                                        name="creditcarnumber"
                                                        type="text"
                                                        placeholder="Número"
                                                        mask={this.state.mask}
                                                        value={this.state.creditcarnumber}
                                                    />
                                                    <Selectll
                                                        onChange={this.handleChange}
                                                        name="cardType"
                                                        value={this.state.cardType}
                                                        disabled
                                                    >
                                                        {this.state.typeCard.map((s) => (
                                                        <option key={s.value} value={s.value}>
                                                            {s.value}
                                                        </option>
                                                        ))}
                                                    </Selectll>
                                                </div>
                                            </Label>
                                            <Label>
                                              <div className="row">
                                                <div className='col-6'>
                                                  Código de seguridad CVV2 *
                                                  <InputMask
                                                      className="inputMask"
                                                      onChange={this.handleChange}
                                                      name="securitycode"
                                                      type="text"
                                                      mask={this.state.maskCvv2}
                                                      placeholder="Código de seguridad"
                                                      value={this.state.securitycode}
                                                  />
                                                </div>
                                                <div className='col-6'>
                                                  Zip code *
                                                  <Input
                                                      className="inputMask"
                                                      onChange={this.handleChange}
                                                      name="zipCode"
                                                      type="number"
                                                      placeholder="Zip code"
                                                      required
                                                      value={this.state.zipCode}
                                                  />
                                                </div>
                                              </div>
                                            </Label>
                                            <Label>
                                                Fecha de Expiración - Mes/Año *
                                                <div>
                                                  <select
                                                      name="expDateMonth"
                                                      value={this.state.expDateMonth}
                                                      onChange={this.handleChange}
                                                      required
                                                  >
                                                      <option value="">
                                                      MM
                                                      </option>
                                                      {Array.from({ length: 12 }, (_, i) => (
                                                      <option key={i + 1} value={String(i + 1).padStart(2, "0")}>
                                                          {String(i + 1).padStart(2, "0")}
                                                      </option>
                                                      ))}
                                                  </select>
                                                  /
                                                  <select
                                                      name="expDateYear"
                                                      value={this.state.expDateYear}
                                                      onChange={this.handleChange}
                                                      required
                                                  >
                                                      <option value="">
                                                      YYYY
                                                      </option>
                                                      {years.map((year) => (
                                                      <option key={year} value={year}>
                                                          {year}
                                                      </option>
                                                      ))}
                                                  </select>
                                                </div>
                                            </Label>
                                          </div>
                                        : 
                                          <div id="form-group-credit-card">
                                            <Label>
                                                Nombre en la tarjeta *
                                                <Input
                                                    name="nameOnCard"
                                                    value={this.state?.infoToken.nameOnCard}
                                                    type="text"
                                                    placeholder="Nombre en la tarjeta"
                                                />
                                            </Label>
                                            <Label>
                                                Número Tarjeta Crédito/ Tipo de tarjeta *
                                                <div className="DivForm">
                                                    <InputMask
                                                        className="inputCcNumber"
                                                        name="creditcarnumber"
                                                        type="text"
                                                        value={'*********'+this.state?.infoToken.last4Digits}
                                                    />
                                                </div>
                                            </Label>
                                            <Label>
                                              <div className="row">
                                                <div className='col-6'>
                                                  Código de seguridad CVV2 *
                                                  <InputMask
                                                      className="inputMask"
                                                      name="securitycode"
                                                      type="text"
                                                      value={'***'}
                                                  />
                                                </div>
                                                <div className='col-6'>
                                                  Zip code *
                                                  <Input
                                                      name="zipcode"
                                                      type="text"
                                                      placeholder="Zip code"
                                                      value={'******'}
                                                  />
                                                </div>
                                              </div>
                                            </Label>
                                            <Label>
                                                Fecha de Expiración - Mes/Año *
                                                <div>
                                                  <select
                                                      name="expDateM"
                                                      value={this.state?.infoToken?.expDate?.slice(0, 2)}
                                                      required
                                                  >
                                                      <option value="">
                                                      MM
                                                      </option>
                                                      {Array.from({ length: 12 }, (_, i) => (
                                                      <option key={i + 1} value={String(i + 1).padStart(2, "0")}>
                                                          {String(i + 1).padStart(2, "0")}
                                                      </option>
                                                      ))}
                                                  </select>
                                                  /
                                                  <select
                                                      name="expDateY"
                                                      value={'20' + this.state?.infoToken?.expDate?.slice(2)}
                                                      required
                                                  >
                                                      <option value="">
                                                      YYYY
                                                      </option>
                                                      {years.map((year) => (
                                                      <option key={year} value={year}>
                                                          {year}
                                                      </option>
                                                      ))}
                                                  </select>
                                                </div>
                                            </Label>
                                          </div>
                                        }
                                    
                                        

                                    </Label>

                                    <div className='DivForm justify-content-center align-items-center'>
                                      <Label>
                                        <input
                                          type="checkbox"
                                          name="usingAutomaticPayment"
                                          onClick={this.handleChange}
                                          //defaultChecked={this.state?.infoToken?.usingAutomaticPayment===true}
                                          defaultChecked={this.state.exisToken === true ? this.state?.infoToken?.usingAutomaticPayment : this.state.usingAutomaticPayment}
                                        />
                                        <small style={{  padding: '10px' }}>
                                          Usar token para
                                          pagos recurrentes
                                        </small>
                                      </Label>
                                      
                                      <div className='mx-4'>
                                        <Button variant="primary" onClick={() => this.deleteToken()} disabled={this.state.exisToken===false}>
                                            Nuevo token
                                        </Button>
                                      </div>

                                      <div>
                                        <Button variant="success" onClick={() => this.save()} disabled={this.state.exisToken===true}>
                                            Crear token
                                        </Button>
                                      </div>
                                      
                                    </div>
                                    
                                </div>
                              </div>
                              :
                              <p>No hay negocios asociados al usuario: {this.state.user.userName + ' ' + this.state.user.lastName}</p>

                            }

                            <br></br>
                            <br></br>
                            
                            <div style={{ padding: "20px" }}>
                              <Accordion items={items} />
                            </div>


                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="warning" onClick={() => this.payModal(false)}>
                                    ATRAS
                                </Button>
                            </Modal.Footer>
                        </Form>
                    }
                </Modal>
            </React.Fragment>
        )
    }
}

export default PayBillMerchant;